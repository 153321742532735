import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { alpha, styled } from '@mui/material';
// ? Material Ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// ? Next js
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { API } from '_helpers';

const Item = styled(Paper)({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
});

interface IServices extends IWithList {
  button: IButton;
}

const Service: React.FC<IProps<IServices>> = ({ data }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 12,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? `${alpha(theme.palette.action.active, 0.02)}`
            : theme.palette.grey.A200,
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Typography
                component="h2"
                color="text.primary"
                variant="h2"
                gutterBottom
              >
                {data.title}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                {data.description}
              </Typography>
            </Stack>
            <Grid item container spacing={2} sx={{ my: 2 }}>
              {data.list.map((el) => (
                <Grid item xs={12} md={6} key={el._id}>
                  <Item elevation={1} sx={{ p: 2 }}>
                    <Stack direction="row" alignItems="center">
                      <DoubleArrowIcon
                        fontSize="small"
                        color="primary"
                        sx={{ mr: 1 }}
                      />
                      <Typography color="text.secondary">{el.item}</Typography>
                    </Stack>
                  </Item>
                </Grid>
              ))}
            </Grid>
            <Link href={data.button.path} passHref>
              <Button
                sx={{
                  my: 2,
                  background: (theme) => alpha(theme.palette.primary.main, 0.1),
                }}
                color="primary"
                component="a"
                disableElevation
                endIcon={<ArrowRightAltIcon />}
              >
                {data.button.label}
              </Button>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            alignItems="center"
            sx={{
              borderRadius: 4,
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '80%',
                height: '100%',
                m: 'auto',
              }}
            >
              <Image
                alt={data.media.alternativeText}
                src={`${API}${data.media.url}`}
                width={data.media.width}
                height={data.media.height}
                priority
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Service;
